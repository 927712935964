<template>
  <v-data-table :headers="headers" :items="items" :server-items-length="meta.total" :options.sync="options"
    :footer-props="{ 'items-per-page-options': [15, 25, 50] }" class="elevation-1 plans-table"
    :item-class="this.itemRowBackground">

    <template v-slot:top>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="searchTitle" append-icon="mdi-magnify" label="Пошук за назвою" single-line hide-details
            class="mx-4"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select v-model="planOrTemplate" :items="planTypes" item-text="title" item-value="id" select hide-details
            label="Тип" clearable class="mx-4"></v-select>
        </v-col>
        <v-col align-self="center">
          <v-btn color="primary" outlined class="ml-2" @click="search"> Пошук </v-btn>
          <v-btn color="primary" class="ml-2" outlined :input-value="filterToggle"
            @click="filterToggle = !filterToggle">
            <v-icon> mdi-filter </v-icon>
          </v-btn>
          <v-btn color="primary" class="ml-2" outlined @click="clear"> Очистити </v-btn>
        </v-col>
      </v-row>

      <v-row v-show="filterToggle" class="px-4 pb-4">
        <v-col cols="12" lg="6" v-if="exceptRoles([ROLES.ID.department])">
          <v-autocomplete v-model="faculty" :items="faculties" item-text="name" item-value="id" label="Факультет"
            hide-details clearable></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" v-if="exceptRoles([ROLES.ID.department])">
          <v-autocomplete v-model="department" :items="departments" item-text="name" item-value="id" label="Кафедра"
            hide-details :loading="departmentsLoading" clearable></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" v-if="exceptRoles([ROLES.ID.guest])">
          <v-autocomplete v-model="division" :items="divisions" item-text="title" item-value="id" hide-details
            label="Представник відділу" clearable></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" v-if="exceptRoles([ROLES.ID.guest])">
          <v-select v-model="verificationDivisionStatus" :items="verificationsDivisionsStatus"
            :disabled="division === null" item-text="title" item-value="id" select hide-details
            label="Статус верифікації" clearable></v-select>
        </v-col>
        <v-col cols="12" lg="6" v-if="allowedRoles([ROLES.ID.root])">
          <v-text-field v-model="planId" label="Пошук за ID плану" single-line hide-details type="number"
            clearable></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" v-if="exceptRoles([ROLES.ID.guest])">
          <v-checkbox v-model="archived" label="Включити арховані документи"></v-checkbox>
        </v-col>
      </v-row>
    </template>

    <template v-slot:item.index="{ index, item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="cursor-pointer">
            {{ (meta.current_page - 1) * meta.per_page + (index + 1) }}
          </span>
        </template>
        <span>ID: {{ item.id }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.type_id="{ item }">
      <span class="text-no-wrap">
        <PublishedBadge :published="item.published" /> {{ item.type_id }}
      </span>
    </template>

    <template v-slot:item.title="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            {{ item.title }}
          </div>
        </template>
        <span>Автор: {{ item.author }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.created_at="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            {{ item.created_at.split(' ')[0] }}
          </div>
        </template>
        <span>{{ item.created_at }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.catalog_speciality="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            {{ item.catalog_speciality ? 'Так' : 'Ні' }}
          </div>
        </template>
        <span v-if="item.catalog_speciality">Присутній каталог дисциплін за СПЕЦІАЛЬНІСТЮ</span>
        <span v-else>Відсутній каталог дисциплін за СПЕЦІАЛЬНІСТЮ</span>
      </v-tooltip>
    </template>

    <template v-slot:item.catalog_education_programs="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            {{ item.catalog_education_programs ? 'Так' : 'Ні' }}
          </div>
        </template>
        <span v-if="item.catalog_education_programs">Присутній каталог дисциплін за ОСВІТНЬОЮ ПРОГРАМОЮ</span>
        <span v-else>Відсутній каталог дисциплін за ОСВІТНЬОЮ ПРОГРАМОЮ</span>
      </v-tooltip>
    </template>

    <template v-slot:item.actions="{ item }">
      <btn-tooltip tooltip="Перегляд">
        <router-link v-if="item.actions.preview"
          :to="{ name: 'PreviewPlan', params: { id: item.id, title: item.title.replaceAll('/', '-') } }"
          target="_blank">
          <v-icon small class="mr-2" color="primary">mdi-eye-outline</v-icon>
        </router-link>
      </btn-tooltip>
      <btn-tooltip tooltip="Скопіювати">
        <v-icon v-if="item.actions.copy" small class="mr-1" color="primary" @click="$emit('copy', item.id, item.title)">
          mdi-content-copy
        </v-icon>
      </btn-tooltip>
      <btn-tooltip tooltip="Редагувати">
        <v-icon v-if="item.actions.edit" small class="mr-1" color="primary" @click="$emit('edit', item.id, item.title)">
          mdi-pencil-outline
        </v-icon>
      </btn-tooltip>
      <btn-tooltip tooltip="Архівувати">
        <v-icon v-if="item.actions.delete" small color="red" @click="$emit('delete', item.id, item.title)">
          mdi-archive-arrow-down-outline
        </v-icon>
      </btn-tooltip>
      <btn-tooltip tooltip="Відновити">
        <v-icon v-if="item.actions.restore" small color="primary" @click="$emit('restore', item.id, item.title)">
          mdi-archive-arrow-up-outline
        </v-icon>
      </btn-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import api from '@/api';
import { API } from '@/api/constants-api';
import RolesMixin from '@/mixins/RolesMixin';
import BackgroundRowMixin from '@/mixins/BackgroundRowMixin';
import { ROLES } from '@/utils/constants';
import { mapGetters } from 'vuex';
import PublishedBadge from '@/components/base/PublishedBadge';

export default {
  name: 'PlansTable',
  data() {
    return {
      ROLES,
      filterToggle: false,
      searchTitle: '',
      headers: [],
      faculty: null,
      faculties: [],
      department: null,
      departments: [],
      departmentsLoading: false,
      division: null,
      divisions: [],
      verificationDivisionStatus: 1,
      verificationsDivisionsStatus: [],
      planId: null,
      planOrTemplate: null,
      planTypes: [],
      archived: false,
    };
  },
  components: {
    PublishedBadge,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    options: {
      get: function () {
        return this.$store.state.plans.options;
      },
      set: function (newValue) {
        this.$store.dispatch('plans/setOptions', this.filterSort(newValue));
        this.update();
      },
    },
  },
  mixins: [RolesMixin, BackgroundRowMixin],
  watch: {
    faculty(v) {
      v !== null ? this.apiGetDepartments(v) : (this.departments = []);
    },
    division(v) {
      if (v === null) {
        this.options.divisionWithStatus = null;
      }
    },
  },
  mounted() {
    this.apiGetDivisions();
    this.generateHeader();
  },
  methods: {
    generateHeader() {
      let headers = [
        { text: '№', value: 'index', sortable: false },
        { text: 'Тип', value: 'type_id', sortable: true },
        { text: 'Назва', value: 'title' },
        { text: 'Факультет', value: 'faculty', sortable: false },
        { text: 'Кафедра', value: 'department', sortable: false },
        { text: 'Рік', value: 'year', width: '70px', align: 'center' },
        { text: 'Дата створення', value: 'created_at', width: '150px', align: 'center' },
        { text: 'Верифікація', value: 'verification', width: '150px', sortable: false },
        { text: 'СП', value: 'catalog_speciality', sortable: false, width: '30px' },
        { text: 'ОП', value: 'catalog_education_programs', sortable: false, width: '30px' },
        { text: 'Дії', value: 'actions', width: '120px', sortable: false },
      ];

      if (this.allowedRoles([ROLES.ID.guest])) {
        headers = headers.filter((item) =>
          item.value != 'verification' && item.value != 'created_at' &&
          item.value != 'catalog_speciality' && item.value != 'catalog_education_programs'
        );
      }

      this.headers = headers;
    },
    update() {
      this.$emit('update', this.options);
    },
    search() {
      this.$store.dispatch('plans/setOptions', this.filterSort({ ...this.options, page: 1 }));
    },
    resetPage() {
      if (this.options.page == 1) {
        this.update();
      } else {
        this.options.page = 1;
      }
    },
    clear() {
      this.searchTitle = this.options.searchTitle = '';

      if (this.exceptRoles([ROLES.ID.department])) {
        this.department = null;
      }

      if (this.exceptRoles([ROLES.ID.faculty_institute, ROLES.ID.department])) {
        this.faculty = null;
      }

      this.options.faculty = null;
      this.options.department = null;
      this.division = this.options.divisionWithStatus = null;
      this.planId = this.options.planId = null;
      this.archived = this.options.archived = null;
      this.planOrTemplate = this.options.planOrTemplate = null;
      this.resetPage();
    },
    apiGetDivisions() {
      api.get(API.PLAN_FILTERS).then(({ data }) => {
        this.divisions = data.divisions;
        this.verificationsDivisionsStatus = data.verificationsStatus;
        this.faculties = data.faculties;
        this.planTypes = data.types;
      });
    },
    apiGetDepartments(id) {
      this.departmentsLoading = true;

      api.show(API.DEPARTMENTS, id).then(({ data }) => {
        this.departments = data.data;
        this.departmentsLoading = false;
      });
    },
    filterSort(values) {
      values.searchTitle = this.searchTitle;
      values.planId = this.planId;
      values.archived = +this.archived;
      values.planOrTemplate = this.planOrTemplate;

      if (this.exceptRoles([ROLES.ID.department])) {
        values.faculty = this.faculty;
        values.department = this.department;
      }

      if (this.division !== null) {
        values.divisionWithStatus = [this.division, this.verificationDivisionStatus];
      }

      return values;
    },
  },
};
</script>

<style>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 10px !important;
}
</style>
